import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import postData from "../../data/news.json";

const { $ } = window;

const listPosts = postData.slice(0, 3).map((post, index) => (
  <div key={index} className="item col-lg-4 col-md-4 col-sm-12 col-sx-12">
    <Link
      to={`/news/${post.slug}`}
      onClick={() => window.scrollTo(0, 0)}
      className="item-wrap"
    >
      <div className="image">
        <img
          src={`../assets/images/blog/${post.postImage}`}
          alt={post.title}
          className="img-responsive"
        />
      </div>
      <div className="item-info equal-height">
        <div className="item-header table fsize-14 fweight-700 uppercase">
          <div className="table-cell platform">{post.platform}</div>
          <div className="table-cell color-2 text-right">
            {post.publishedAt}
          </div>
        </div>
        <div className="item-title mt20" data-trim="40">
          {post.title}
        </div>
        <div className="item-text mt25 lheight-26" data-trim="130">
          {post.description}
        </div>
      </div>
    </Link>
  </div>
));

function LatestPosts() {
  useEffect(() => {
    $(".equal-height").matchHeight();
  }, []);

  return (
    <Fragment>
      <section className="blog-section ptb90">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="title-bl text-center wow fadeIn"
                data-wow-duration="2s"
              >
                <div className="title color-white">Our latest Posts:</div>
              </div>
              <div className="title-description mt30 fweight-300 text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </div>
            </div>
          </div>
        </div>
        <div className="bg-wrapper each-element mt90 clearfix">
          <div className="container">
            <div className="row">{listPosts}</div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default LatestPosts;
