import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import gameData from "../data/games.json";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function NextArrow(props) {
  const { onClick } = props;
  return <div className={`fa fa-angle-right slick-arrow`} onClick={onClick} />;
}

function PrevArrow(props) {
  const { onClick } = props;
  return <div className={`fa fa-angle-left slick-arrow`} onClick={onClick} />;
}

const displayRating = (stars) => {
  let rating = [];
  for (let i = 0; i < stars; i++) {
    rating.push(i);
  }
  return rating;
};

function GamesDetail() {
  const { id } = useParams();
  const getGame = gameData.filter((game) => game.slug === id);

  if (getGame.length === 0) {
    window.location.href = "/games";
  }

  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    infinite: true,
    dots: false,
    prevArrow: <NextArrow />,
    nextArrow: <PrevArrow />,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const getGameImages = getGame[0].gameDetailImages.map((gameImage, index) => (
    <div key={index} className="image-wrapper">
      <Link
        to={`../assets/images/single-game/${gameImage}`}
        data-lightbox="game-slides"
      >
        <img
          src={`../assets/images/single-game/${gameImage}`}
          alt=""
          className="img-responsive"
        />
      </Link>
    </div>
  ));

  const listGame = getGame.map((game, index) => (
    <Fragment key={index}>
      <div className="row">
        <div className="top-bl gdlr30">
          <div className="game-img-slider bg-arrow">
            <Slider {...settings}>{getGameImages}</Slider>
          </div>
        </div>
      </div>
      <div className="row mt80 plr15">
        <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 mb30">
          <div className="tab-head table uppercase fsize-14 fweight-700">
            <div className="table-cell valign-middle ws-20 color-1">
              {game.type}
            </div>
            <div className="table-cell valign-middle text-right">
              {game.publishedAt}
            </div>
          </div>
          <div className="uppercase fsize-32 fweight-700 font-agency color-white lheight-normal">
            {game.title}
          </div>
          <div
            className="mt30 lheight-26 fweight-300"
            dangerouslySetInnerHTML={{ __html: game.content }}
          />
          <div className="mt30">
            <div className="rating text-left">
              {displayRating(game.stars).map((index) => {
                return (
                  <i key={index} className="fa fa-star" aria-hidden="true"></i>
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 mb30 text-center">
          <div className="image-bl">
            <img
              className="game-full-img"
              src={`../assets/images/${game.gameSlideImage}`}
              alt={game.title}
            />
          </div>
        </div>
      </div>
    </Fragment>
  ));

  return (
    <Fragment>
      <Helmet>
        <title>{getGame[0].title} | Launch Planet</title>
        <meta
          name="description"
          content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        />
      </Helmet>
      <section
        className="games parallax"
        data-paroller-factor="0.1"
        data-paroller-type="background"
        data-paroller-direction="vertical"
      >
        <div className="container">
          <div className="row">
            <h1 className="page-title">{getGame[0].title}</h1>
            <div className="breadcrumbs">
              <Link to="/">Home</Link> /<Link to="/games">Games</Link> /
              <span className="color-1">{getGame[0].title}</span>
            </div>
          </div>
        </div>
      </section>
      <section className="ptb150">
        <div className="container">{listGame}</div>
      </section>
    </Fragment>
  );
}

export default GamesDetail;
