import React, { Fragment, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

const { $ } = window;

function Header() {
  useEffect(() => {
    $("header").on("click", ".toggle_menu", function () {
      $(this).toggleClass("open");
      if ($(this).hasClass("open")) {
        $(".menu").addClass("open");
        $("body").addClass("no-scroll");
      } else {
        $(".menu").removeClass("open");
        $(".menu-item-has-children").removeClass("open-list");
        $("body").removeClass("no-scroll");
      }
    });

    $("header").on("click", ".menu li a", function () {
      $(".toggle_menu").toggleClass("open");
      $(".menu").removeClass("open");
      $(".menu-item-has-children").removeClass("open-list");
      $("body").removeClass("no-scroll");
    });

    $("header").on("click", ".menu.open a", function (e) {
      if ($(this).siblings().length) {
        if (!$(this).parent().hasClass("open-list")) {
          $(this).parent().addClass("open-list");
          e.preventDefault();
        } else {
          $(this).parent().removeClass("open-list");
        }
      }
    });
  }, []);
  return (
    <Fragment>
      <div className="header-line-wrapper">
        <header className="header-wrapper fixed-top plr100">
          <div className="table height-100p">
            <div className="table-row">
              <div className="table-cell valign-middle text-left">
                <Link to="/" className="logo">
                  <img
                    src="../assets/images/logo.png"
                    alt="Launch Planet"
                    className="img-responsive inline-block"
                  />
                </Link>
              </div>
              <div className="table-cell valign-top text-center vm-sm">
                <div className="main-menu">
                  <span className="toggle_menu">
                    <span></span>
                  </span>
                  <ul className="menu clearfix">
                    <li className="inline-block">
                      <NavLink exact to="/" activeClassName="active">
                        Home
                      </NavLink>
                    </li>
                    <li className="inline-block">
                      <NavLink to="/about" activeClassName="active">
                        About
                      </NavLink>
                    </li>
                    <li className="inline-block">
                      <NavLink to="/games" activeClassName="active">
                        Games
                      </NavLink>
                    </li>
                    <li className="inline-block">
                      <NavLink to="/news" activeClassName="active">
                        News
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </Fragment>
  );
}

export default Header;
