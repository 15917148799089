import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

function NotFound() {
  return (
    <Fragment>
      <Helmet>
        <title>404 Page Not Found | Launch Planet</title>
        <meta
          name="description"
          content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        />
      </Helmet>
      <section className="table page-404">
        <div className="table-row">
          <div className="table-cell valign-middle text-center">
            <div>
              <img
                src="../assets/images/404/404-img.png"
                alt="Page Not Found"
              />
            </div>
            <div className="title-error mt30">SORRY, PAGE NOT FOUND!</div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default NotFound;
