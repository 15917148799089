import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const { $ } = window;

function About() {
  useEffect(() => {
    $(".equal-height").matchHeight();
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>About | Launch Planet</title>
        <meta
          name="description"
          content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        />
      </Helmet>
      <section
        className="about parallax"
        data-paroller-factor="0.1"
        data-paroller-type="background"
        data-paroller-direction="vertical"
      >
        <div className="container">
          <div className="row">
            <h1 className="page-title">About</h1>
            <div className="breadcrumbs">
              <Link to="/">Home</Link> /<span className="color-1">About</span>
            </div>
          </div>
        </div>
      </section>
      <section className="about-info ptb150 plr15">
        <div className="container">
          <div className="row">
            <div className="item-left col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="equal-height">
                <div>
                  <div className="inline-block">
                    <span className="text-gradient fsize-80 fweight-700 font-agency uppercase">
                      12
                    </span>
                  </div>
                  <div className="inline-block">
                    <span className="s_title block">Years in</span>
                    <span className="s_subtitle block">Games Experience</span>
                  </div>
                </div>
                <div className="lheight-26">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum
                  </p>
                </div>
                <ul className="list-1 pt10">
                  <li className="color5">
                    Excepteur sint occaecat cupidatat non proident
                  </li>
                  <li className="color5">
                    sunt in culpa qui officia deserunt mollit
                  </li>
                </ul>
              </div>
            </div>
            <div className="item-right col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center">
              <div className="image-bl">
                <img
                  className="game-full-img"
                  src="../assets/images/585x550.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default About;
