import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import gameData from "../../data/games.json";

const { $ } = window;

function NextArrow(props) {
  const { onClick } = props;
  return <div className={`fa fa-angle-right slick-arrow`} onClick={onClick} />;
}

function PrevArrow(props) {
  const { onClick } = props;
  return <div className={`fa fa-angle-left slick-arrow`} onClick={onClick} />;
}

const displayRating = (stars) => {
  let rating = [];
  for (let i = 0; i < stars; i++) {
    rating.push(i);
  }
  return rating;
};

const listGames = gameData.map((game, index) => (
  <li
    key={index}
    className={`${index === 0 ? "active " : ""}col-lg-3 col-md-3`}
  >
    <Link to={`#tab-item-${index}`} data-toggle="tab">
      <div className="img-wrap">
        <img src={`../assets/images/${game.gameSlideImage}`} alt={game.title} />
      </div>
      <div className="nav-header-bl mt25">
        <div className="nav-title color-white">{game.title}</div>
        <div className="date">{game.publishedAt}</div>
      </div>
    </Link>
  </li>
));

const listGameDetails = gameData.map((game, index) => (
  <div
    className={`tab-pane fade ${
      index === 0 ? "active " : ""
    } in text-left clearfix`}
    id={`tab-item-${index}`}
    key={index}
  >
    <div className="tab-info col-lg-6 col-md-12 col-sm-12 col-xs-12 ptb90 pl100 equal-height">
      <div className="tab-head table uppercase fsize-14 fweight-700">
        <div className="table-cell valign-middle ws-20 color-1">
          {game.type}
        </div>
        <div className="table-cell valign-middle text-right">
          {game.publishedAt}
        </div>
      </div>
      <div className="uppercase fsize-32 fweight-700 font-agency color-white lheight-normal">
        {game.title}
      </div>
      <div
        className="mt50 lheight-26 fweight-300"
        dangerouslySetInnerHTML={{ __html: game.content }}
      />
      <div className="mt60 table g-bottom">
        <div className="table-cell valign-middle">
          <Link
            to={`/games/${game.slug}`}
            onClick={() => window.scrollTo(0, 0)}
            className="btn primary color-white plr60 ptb19"
          >
            Read more
          </Link>
        </div>
        <div className="rating table-cell valign-middle text-right">
          {displayRating(game.stars).map((index) => {
            return (
              <i key={index} className="fa fa-star" aria-hidden="true"></i>
            );
          })}
        </div>
      </div>
    </div>
    <div className="tab-img col-lg-6 col-md-12 col-sm-12 col-xs-12 pr0 equal-height">
      <div className="image-bl">
        <img
          className="game-full-img"
          src={`../assets/images/${game.gameSlideImage}`}
          alt={game.title}
        />
      </div>
    </div>
  </div>
));

function UpcomingGames() {
  useEffect(() => {
    $(".equal-height").matchHeight();

    $(".tabs-slider").on("click", "li", function () {
      $(this).addClass("active");
      if ($(this).hasClass("active")) {
        $(".tabs-slider li").removeClass("active");
      }
    });
  }, []);
  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1120,
        settings: {
          autoplay: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Fragment>
      <section className="game-section pt150 pb85">
        <div className="container">
          <div className="row">
            <div
              className="title-bl text-center wow fadeIn"
              data-wow-duration="2s"
            >
              <div className="title color-white">UpComing Games:</div>
            </div>
            <div className="tm-tabs tabs mt70">
              <div className="tabs-nav">
                <ul className="list-inline nav nav-tabs tabs-slider">
                  <Slider {...settings}>{listGames}</Slider>
                </ul>
              </div>
              <div className="tab-content relative mt90">{listGameDetails}</div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default UpcomingGames;
