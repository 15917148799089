import React, { Fragment } from "react";
import { Link, NavLink } from "react-router-dom";

function Footer() {
  return (
    <Fragment>
      <footer className="footer">
        <div className="container">
          <div className="row mb110">
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb25">
              <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                <img src="../assets/images/logo.png" alt="" />
              </Link>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 text-right">
              <ul className="footer-menu">
                <li className="active">
                  <NavLink
                    exact
                    to="/"
                    onClick={() => window.scrollTo(0, 0)}
                    activeClassName="active"
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/about"
                    onClick={() => window.scrollTo(0, 0)}
                    activeClassName="active"
                  >
                    About
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/games"
                    onClick={() => window.scrollTo(0, 0)}
                    activeClassName="active"
                  >
                    Games
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/news"
                    onClick={() => window.scrollTo(0, 0)}
                    activeClassName="active"
                  >
                    News
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/privacy-policy"
                    onClick={() => window.scrollTo(0, 0)}
                    activeClassName="active"
                  >
                    Privacy Policy
                  </NavLink>
                </li>
              </ul>
              <div className="mt60 desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris.
              </div>
              <div className="social-content mt30">
                <div className="social-list">
                  <Link to="#">
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </Link>
                </div>
                <div className="social-list">
                  <Link to="#">
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                  </Link>
                </div>
                <div className="social-list">
                  <Link to="#">
                    <i className="fa fa-google-plus" aria-hidden="true"></i>
                  </Link>
                </div>
                <div className="social-list">
                  <Link to="#">
                    <i className="fa fa-youtube-play" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="copyright ptb30 col-lg-12">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                Launch Planet © Copyright 2020
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                All Rights Reserved
              </div>
            </div>
          </div>
        </div>
      </footer>
      <Link to="#" id="toTop" className="color-white">
        <i className="fa fa-angle-up fsize-14" aria-hidden="true"></i>
      </Link>
    </Fragment>
  );
}

export default Footer;
