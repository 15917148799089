import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Home from "./views/Home";
import About from "./views/About";
import Games from "./views/Games";
import GamesDetail from "./views/GamesDetail";
import News from "./views/News";
import NewsDetail from "./views/NewsDetail";
import PrivacyPolicy from "./views/PrivacyPolicy";
import NotFound from "./views/NotFound";

import "./App.css";

function App() {
  return (
    <Router>
      <Fragment>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/games" component={Games} />
          <Route exact path="/games/:id" component={GamesDetail} />
          <Route exact path="/news" component={News} />
          <Route exact path="/news/:id" component={NewsDetail} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route component={NotFound} />
        </Switch>
        <Footer />
      </Fragment>
    </Router>
  );
}

export default App;
