import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import "./Banners.css";
import bannerData from "../../data/banners.json";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const listBanners = bannerData.map((banner, index) => (
  <div key={index} data-src={`../assets/images/${banner.image}`}>
    <h3>{banner.title}</h3>
    <h1>{banner.subTitle}</h1>
    <p>{banner.content}</p>
    <div className="button-container">
      <Link to={banner.buttonUrl} onClick={() => window.scrollTo(0, 0)}>
        {banner.buttonName}
      </Link>
    </div>
  </div>
));

const slider = (
  <Fragment>
    <AutoplaySlider
      play={true}
      cancelOnInteraction={false} // should stop playing on user interaction
      interval={6000}
      organicArrows={false}
    >
      {listBanners}
    </AutoplaySlider>

    <div className="sl-social bg-white">
      <Link to="#" className="social facebook">
        <i className="fa fa-facebook color-3" aria-hidden="true"></i>
      </Link>
      <Link to="#" className="social twitter">
        <i className="fa fa-twitter color-3" aria-hidden="true"></i>
      </Link>
      <Link to="#" className="social google">
        <i className="fa fa-google-plus color-3" aria-hidden="true"></i>
      </Link>
    </div>
  </Fragment>
);

function Banners() {
  return (
    <Fragment>
      <section className="main-slider">{slider}</section>
    </Fragment>
  );
}

export default Banners;
