import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import postData from "../data/news.json";

const { $ } = window;

const listPosts = postData.map((post, index) => (
  <div key={index} className="col-lg-12 col-md-12">
    <article className="vertical-item format-thumb fsize-0 clearfix">
      <div className="item-left-img col-lg-2 col-md-2 col-sm-12 col-xs-12 equal-height">
        <Link to={`/news/${post.slug}`} onClick={() => window.scrollTo(0, 0)}>
          <img
            src={`../assets/images/blog/${post.postImage}`}
            alt={post.title}
          />
        </Link>
      </div>
      <div className="post-content col-lg-10 col-md-10 col-sm-12 col-xs-12 equal-height">
        <div className="post-wrapper">
          <div className="table">
            <div className="table-row">
              <div className="table-cell valign-top">
                <div className="platform fsize-14 fweight-700 uppercase">
                  {post.platform}
                </div>
              </div>
              <div className="table-cell valign-top text-right">
                <div className="fsize-14 fweight-700 uppercase">
                  {post.publishedAt}
                </div>
              </div>
            </div>
          </div>
          <div className="mt15">
            <Link
              to={`/news/${post.slug}`}
              onClick={() => window.scrollTo(0, 0)}
              className="post-title"
            >
              <h5>{post.title}</h5>
            </Link>
            <div className="fsize-16 lheight-26 mt15" data-trim="140">
              {post.description}
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
));

function News() {
  useEffect(() => {
    $(".equal-height").matchHeight();
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>News | Launch Planet</title>
        <meta
          name="description"
          content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        />
      </Helmet>
      <section
        className="news parallax"
        data-paroller-factor="0.1"
        data-paroller-type="background"
        data-paroller-direction="vertical"
      >
        <div className="container">
          <div className="row">
            <h1 className="page-title">News</h1>
            <div className="breadcrumbs">
              <Link to="/">Home</Link> /<span className="color-1">News</span>
            </div>
          </div>
        </div>
      </section>
      <section className="blog-content ptb150 each-element">
        <div className="container">
          <div className="row">{listPosts}</div>
        </div>
      </section>
    </Fragment>
  );
}

export default News;
