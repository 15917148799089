import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Banners from "../components/layout/Banners";
import UpcomingGames from "../components/layout/UpcomingGames";
import LatestPosts from "../components/layout/LatestPosts";

import "./Home.css";

function Home() {
  return (
    <Fragment>
      <Helmet>
        <title>Launch Planet: Games Just For You</title>
        <meta
          name="description"
          content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        />
      </Helmet>
      <Banners />
      <UpcomingGames />
      <LatestPosts />
    </Fragment>
  );
}

export default Home;
