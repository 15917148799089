import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import postData from "../data/news.json";

function NewsDetail() {
  const { id } = useParams();
  const getPost = postData.filter((post) => post.slug === id);

  if (getPost.length === 0) {
    window.location.href = "/news";
  }

  let index = postData.findIndex((post) => post.slug === id);

  function nextItem(i) {
    i = i + 1;
    i = i % postData.length;
    return postData[i];
  }

  function prevItem(i) {
    if (i === 0) {
      i = postData.length;
    }
    i = i - 1;
    return postData[i];
  }

  const listPost = getPost.map((post, index) => (
    <Fragment key={index}>
      <div className="uppercase fsize-40 fweight-700 font-agency color-white lheight-normal text-center">
        {post.title}
      </div>
      <div className="post-image mt30 gdlr30">
        <img
          src={`../assets/images/post/${post.postImage970x646}`}
          alt={post.title}
        />
      </div>
      <div className="post-bottom background-4">
        <div className="p60 plr30">
          <div className="table">
            <div className="table-row">
              <div className="table-cell valign-top">
                <div className="fsize-14 fweight-700 uppercase color-1">
                  {post.platform}
                </div>
              </div>
              <div className="table-cell valign-top text-right">
                <div className="fsize-14 fweight-700 uppercase">
                  {post.publishedAt}
                </div>
              </div>
            </div>
          </div>
          <div
            className="mt20 lheight-26"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
        </div>
      </div>
    </Fragment>
  ));

  return (
    <Fragment>
      <Helmet>
        <title>{getPost[0].title} | Launch Planet</title>
        <meta
          name="description"
          content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        />
      </Helmet>
      <section
        className="news parallax"
        data-paroller-factor="0.1"
        data-paroller-type="background"
        data-paroller-direction="vertical"
      >
        <div className="container">
          <div className="row">
            <h1 className="page-title">{getPost[0].title}</h1>
            <div className="breadcrumbs">
              <Link to="/">Home</Link> /<Link to="/news">News</Link> /
              <span className="color-1">{getPost[0].title}</span>
            </div>
          </div>
        </div>
      </section>
      <section className="ptb150">
        <div className="container">
          <div className="row">
            <div className="full-col col-lg-10 col-md-10 col-md-push-1">
              <div className="posts-nav">
                <div className="row">
                  <div className="col-md-6">
                    <Link
                      to={`/news/${prevItem(index).slug}`}
                      className="nav_prev nav-btn td-none background-4"
                    >
                      <div className="nav_content">
                        <div className="fsize-14 fweight-700 uppercase color-1">
                          Previous Post
                        </div>
                        <div className="nav-title font-agency fsize-24 fweight-700 color-white uppercase">
                          {prevItem(index).title}
                        </div>
                        <div
                          className="fsize-16 lheight-26 mt15"
                          data-trim="140"
                        >
                          {prevItem(index).description}
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-6 text-right">
                    <Link
                      to={`/news/${nextItem(index).slug}`}
                      className="nav_next nav-btn td-none background-4"
                    >
                      <div className="nav_content">
                        <div className="fsize-14 fweight-700 uppercase color-1">
                          Next Post
                        </div>
                        <div className="nav-title font-agency fsize-24 fweight-700 color-white uppercase">
                          {nextItem(index).title}
                        </div>
                        <div
                          className="fsize-16 lheight-26 mt15"
                          data-trim="140"
                        >
                          {nextItem(index).description}
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="post-content mt30">{listPost}</div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default NewsDetail;
