import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import gameData from "../data/games.json";

const displayRating = (stars) => {
  let rating = [];
  for (let i = 0; i < stars; i++) {
    rating.push(i);
  }
  return rating;
};

const listGames = gameData.map((game, index) => (
  <div
    key={index}
    className="g-item col-lg-4 col-md-4 col-sm-6 col-xs-12 mb30 wow fadeInUp"
    data-wow-duration="1s"
  >
    <div className="image-container">
      <Link to={`/games/${game.slug}`} onClick={() => window.scrollTo(0, 0)}>
        <img
          src={`../assets/images/games/${game.gameImage}`}
          alt={game.title}
          className="img-responsive"
        />
      </Link>
    </div>
    <div className="bottom-container">
      <div className="table">
        <div className="table-row">
          <div className="table-cell fsize-14 color-8 fweight-700 uppercase">
            {game.type}
          </div>
          <div className="table-cell fsize-14 color-2 fweight-700 uppercase text-right">
            {game.publishedAt}
          </div>
        </div>
      </div>
      <div className="text-center mt20">
        <Link
          to={`/games/${game.slug}`}
          onClick={() => window.scrollTo(0, 0)}
          className="g-name fsize-30 fweight-700 font-agency uppercase color-white"
        >
          {game.title}
        </Link>
        <div className="mt20 color-2 lheight-26 equal-height" data-trim="90">
          {game.description}
        </div>
        <div className="rating text-center mt20">
          {displayRating(game.stars).map((index) => {
            return (
              <i key={index} className="fa fa-star" aria-hidden="true"></i>
            );
          })}
        </div>
      </div>
    </div>
  </div>
));

function Games() {
  return (
    <Fragment>
      <Helmet>
        <title>Games | Launch Planet</title>
        <meta
          name="description"
          content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        />
      </Helmet>
      <section
        className="games parallax"
        data-paroller-factor="0.1"
        data-paroller-type="background"
        data-paroller-direction="vertical"
      >
        <div className="container">
          <div className="row">
            <h1 className="page-title">Games</h1>
            <div className="breadcrumbs">
              <Link to="/">Home</Link> /<span className="color-1">Games</span>
            </div>
          </div>
        </div>
      </section>
      <section className="ptb150">
        <div className="container">
          <div className="row">
            <div className="games-container">{listGames}</div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default Games;
